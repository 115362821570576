.sourceObjectSearch{
    
    &.back-link{
        position: absolute;
        color: var(--primary-selected-text);
        font-weight: bold;
        top:1rem;
        right:.5rem;
        margin-right:1rem;
        height:1.25rem;
        cursor: pointer;
        z-index: 100;
    }
}