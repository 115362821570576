.homepage {
    overflow-y: scroll;
    scroll-behavior: smooth;
}

section {
    min-height: 100vh;
}

#landing {
    background-size: 20px 20px;
    background-image:
        linear-gradient(to right, var(--secondary-background) 1.5px, transparent 1px),
        linear-gradient(to bottom, var(--secondary-background) 1.5px, transparent 1px), ;
    background-position: -10px -15px;
    display: flex;
    justify-content: center;
    position: relative;

    .left-column {
        padding: 14rem 0 0 4.5rem;
        min-width: 35%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .homepage-title {
        font-size: 4rem;
        font-weight: 400;
        text-align: center;
        line-height: 4.6rem;
        font-family: Roboto;
    }

    .bold {
        font-weight: 700;
    }

    .btn-wrapper {
        padding: 3.5rem 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .login-btn {
        width: 147px;
        height: 47px;
        background-color: var(--inverse-primary-background);
        color: #F5F5F5;
        font-size: 1.3rem;
        font-weight: 600;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: 2.5rem;
    }

    .search-portal {
        border: none;

        span {
            font-weight: unset;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .right-column {
        padding: 7rem 4.5rem 0 0;
        display: flex;
        justify-content: center;
    }

    .img-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        filter: url('#flt_tag');
        position: relative;

        .img {
            clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
            width: 100%;
            height: 100%;
            position: absolute;
            background-size: cover;
        }

        .flt_svg {
            visibility: hidden;
            width: 0px;
            height: 0px;
        }
    }

    .left {
        width: 16.7rem;
        height: 28.5rem;
        margin-top: 6rem;
        left: 2rem;

        .img {
            background-image: url('../../../content/homeImages/home-image1.png');
            background-position: 78%;
        }
    }

    .middle {
        width: 20rem;
        height: 34.3rem;

        .img {
            background-image: url('../../../content/homeImages/home-image2.jpg');
            background-position: 40% 50%;
            background-size: 110rem;
        }
    }

    .right {
        width: 16.4rem;
        height: 28rem;
        // margin-top: -6rem;
        left: -3rem;

        .img {
            background-image: url('../../../content/homeImages/home-image3.jpg');
            background-position: 59% 24%;
            background-size: 90rem;
        }
    }

    .background-fade {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 17rem;
        background-image: linear-gradient(180deg, transparent, rgba(245, 245, 245, 0.6), var(--primary-background) 40%);
    }
}

#about {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4.5rem;
    margin-top: -5rem;

    .top-half,
    .bottom-half {
        padding: 0 4.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5rem;
        z-index: 1;

    }

    .img-wrapper,
    .paragraph-wrapper {
        width: 50%;
    }

    .about-img {
        width: 100%;
        max-width: 36rem;
    }

    .paragraph-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    p {
        font-size: 1.25rem;
        line-height: 1.4rem;
        width: 100%;
        max-width: 36rem;
    }
}

#franchises {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;

    .search-wrapper {
        padding: 1rem 0;
        width: 33rem;
        position: relative;
    }

    .field-control__wrapper {
        margin: unset;

    }

    .search-icon {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.2rem;
        color: var(--primary-border);
        z-index: 1;
    }

    input {
        padding-left: 3rem;
        font-size: 1rem;
        font-family: Roboto;

        &::placeholder {
            color: var(--primary-text);
            opacity: 1;
        }
    }

    .franchisee-img-container {
        max-width: calc(19rem * 5);
        width: 100%;
        margin-top: 2rem;
        padding: 2rem 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;

        img,
        .placeholder {
            object-fit: contain;
            width: 18rem;
            height: 9rem;
            background-color: var(--secondary-background);
            border-radius: .5rem;
            padding: 1rem;
        }

        .img {
            cursor: pointer;
        }
    }
}


@media (max-width: 1200px) {
    #landing {
        .left-column {
            padding-left: 3.5rem;
        }

        .right-column {
            padding-right: 0;
        }
    }

    #franchises {
        .img-wrapper {
            padding: 2rem;
        }
    }
}

@media (max-width: 1024px) {
    #landing {
        .left-column {
            padding-left: 3rem;
        }

        .homepage-title {
            font-size: 3.3rem;
        }

        .left {
            width: 13.7rem;
            height: 25.5rem;

        }

        .middle {
            width: 17rem;
            height: 31.3rem;
        }

        .right {
            width: 13.4rem;
            height: 25rem;
        }
    }

    #franchises {
        .img-wrapper {
            padding: 2rem 0;
        }
    }
}

@media (max-width: 768px) {
    #landing {
        display: flex;
        flex-direction: column;
        justify-content: unset;
        gap: 2rem;

        .left-column {
            padding: 0;
            order: 1;
        }

        .homepage-title {
            font-size: 3rem;
        }

        .btn-wrapper {
            padding: 2rem 0;
        }

        .right-column {
            padding: 4rem 0 0 2rem;
            order: 0;
        }

        .left {
            width: 12.7rem;
            height: 24.5rem;
            left: 1.5rem;

            .img {
                background-position: 76%;
            }
        }

        .middle {
            width: 16rem;
            height: 30.3rem;

            .img {
                background-size: 80rem;
            }
        }

        .right {
            width: 12.4rem;
            height: 24rem;
            left: -2.5rem;
            margin-top: 1rem;

            .img {
                background-size: 80rem;
            }
        }

        .background-fade {
            height: 10rem;
        }
    }

    #about {
        margin-top: -2rem;
        gap: 3rem;

        .top-half,
        .bottom-half {
            display: flex;
            flex-direction: column;
            // padding: 0;
            gap: 2rem;
        }

        .img-wrapper,
        .paragraph-wrapper {
            width: 100%;
        }

        .bottom-half {
            .img-wrapper {
                order: 0;
            }

            .paragraph-wrapper {
                order: 1;
            }
        }
    }

    #franchises {
        margin-top: 10rem;

        .img-wrapper {
            padding: 2rem 0;
        }
    }
}

@media (max-width: 480px) {
    #landing {
        .left {
            width: 9.7rem;
            height: 21.5rem;
            left: 1rem;
            margin-top: 5rem;

            .img {
                background-position: 74%;
            }
        }

        .middle {
            width: 12rem;
            height: 26.3rem;

            .img {
                background-size: 75rem;
            }
        }

        .right {
            width: 9.4rem;
            height: 21rem;
            left: -2rem;

            .img {
                background-size: 60rem;
            }
        }
    }

    #about {

        .top-half,
        .bottom-half {
            padding: 0 4rem;
        }
    }

    #franchises {
        .search-wrapper {
            width: 24rem;
        }

        .img-wrapper {
            .img {
                width: 15rem;
            }
        }
    }
}