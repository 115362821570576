.dialog-control__block-ui { 
   .report-dialog {
      max-width: unset;
      width: 62rem;
      height: 44rem;
      
      .dialog {
         max-width: unset;
         max-height: unset;
         
         &__header {
            margin-bottom: 1rem;
         }
         
         &__inner {
            margin-bottom: unset;
         }
      }
      
      iframe {
         border: unset;
         border-radius: var(--primary-border-radius);
      }
   }
}

.report-card {
   display: flex;
   justify-content: center;
   align-items: center;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: var(--dialog-z-index);

   .report {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background-color: var(--primary-background);
      padding: 1.25rem 1rem;
      width: 100%;
      height: 100%;


      &-desc {
         margin-bottom: .4rem;
         margin-top: .2rem;
         font-weight: 500;
      }

      &__header {
         display: flex;
         justify-content: space-between;
         margin-bottom: 1rem;

         &-title {
            display: flex;
            flex-direction: column;
            position: relative;
         }

         &-subtitle {
            font-weight: 500;
            padding-top: .50rem;
         }

         &-button-container {
            height: fit-content;
            display: flex;
            gap: 1rem;
         }

      }

      &__error {
         color: var(--error);
         margin-bottom: 1rem;
         max-height: 29rem;
      }

      &__inner {
         width: 100%;
         min-height: 5rem;
         margin-left: auto;
         margin-right: auto;
         height: 100%;
      }

      iframe {
         border: unset;
         border-radius: var(--primary-border-radius);
      }
   }
}