.entity-object-field-map {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;


    .loading-wrapper {
        display: flex;
        flex-grow: 1;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .back-link {
        color: var(--primary-selected-text);
        font-weight: bold;
        margin-right: 1rem;
        height: 1.25rem;
        cursor: pointer;
        z-index: 100;
    }

    .header-row {
        border-bottom: 1px solid var(--primary-border);
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .eofm {
        display: flex;

        gap: 3rem;
        justify-content: space-between;
        overflow: hidden;

        ::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }

        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            -webkit-border-radius: 10px;
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
            -webkit-border-radius: 10px;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.3);
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        }

        ::-webkit-scrollbar-thumb:window-inactive {
            background: rgba(255, 255, 255, 0.3);
        }


        .field-container {
            gap: .25rem;
            padding-bottom: 1rem;
            align-items: center;
            width: 100%;
        }

        .field {
            width: 16rem;
            min-height: 4rem;
            border: 1px solid var(--primary-border);
            border-radius: .25rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 1rem;
            gap: 1rem;



            &.active {
                // border: 1px solid var(--primary-focus-border);
                border: 1px solid green;
                background-color: lightgreen;
            }
        }

        .fieldmap-count {
            margin-left: auto;
            font-weight: 500;
            color: var(--error);

            &-mapped {
                color: green;
            }
        }

        .importance {
            margin-left: .25rem;
            width: 3rem;
        }

        .checkbox-wrapper {
            height: 1rem;
            width: 1rem;

            .checkbox {
                margin-bottom: unset;
                width: 1rem;
                height: 1rem;
            }
        }


        .eofm-column {
            min-width: 20rem;
            width: 20rem;
            border-right: 1px solid var(--primary-border);
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .column-header {
                margin-left: 2rem;
            }
        }

        .column-header {
            display: flex;
            justify-content: center;
            font-size: 1.2rem;
            width: 100%;
            flex-direction: column;
            height: 5rem;
            gap: .75rem;

            span {
                overflow: hidden;
                text-wrap: nowrap;
                text-overflow: ellipsis;
            }

            .sub-title-wrapper {
                display: flex;
                height: 1.25rem;
                width: 100%;

                .importance {
                    margin-right: 1rem;
                    margin-left: auto;
                }
            }

            .sub-title {
                font-size: 1rem;

            }
        }


        .sobj-column-container {
            flex-grow: 1;
            display: flex;
            gap: 1rem;
            overflow-x: auto;
            overflow-y: hidden;
            padding-right: 3rem;
        }

        .sobj-column {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 20rem;
            max-width: 20rem;
            flex-grow: 1;

            .field-container {
                max-height: 80%;
                border: 1px solid var(--primary-border);
                padding: 1rem 0;
                border-radius: .5rem;
            }

            &-addnew {
                .field-container {
                    flex-grow: 1;
                    margin-top: 5rem;
                    padding: 0rem 1rem;
                }

                .sobj-selectlist,
                .loading {
                    margin-top: .5rem;
                }

                .loading-source-object {
                    display: none;
                }
            }


        }




    }

    .footer {
        display: flex;
        justify-content: flex-end;
        padding: .5rem 1rem;

        .btn-control {
            width: 8rem;
        }

    }
}