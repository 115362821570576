.accordion-container {
    max-width: 46rem;

    .accordion-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        cursor: pointer;
        .accordion-button-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2rem;
        }
    }
    .fa-angle-down {
        transition: all 250ms ease-in;
        &.open {
            rotate: -180deg;
        }
    }
    .accordion-fields-container {
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        max-height: 0;
    }
}