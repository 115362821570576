.wizard-start {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .btn-control {
        margin-bottom: 3.5rem;
    }
}

.wizard-center-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10rem;
    margin-bottom: 2rem;

    h1 {
        font-family: 'Roboto';
        color: var(--primary-selected-text);
        margin-bottom: 1rem;
    }
}

.wizard-continue-select {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 24rem;

    h5 {
        font-weight: 500;
        font-family: "Roboto";
        font-size: 1rem;
        margin-bottom: .5rem;
    }
}