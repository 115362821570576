.select-tree-option-container {
    .select-tree-option-children {
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        max-height: 0;
    }
    .select-tree-option {
        display: flex;
        padding: 0.25rem;
        gap: .5rem;
        height: 1.8rem;
        width: fit-content;
        .select-tree-option-text {
            display: flex;
            gap: .5rem;
            height: 1.8rem;
        }

        .select-tree-text {
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            gap: .5rem;

            .list-placeholder {
                color: var(--primary-placeholder);
            }

            .select-icon,
            .text-icon {
                width: 2rem;
                height: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-right: .5rem;
            }
        }
    }
    .select-tree-option-icon {
        cursor: pointer;
        .fa-angle-down {
            transition: all 250ms ease-in;
            &.open {
                rotate: -90deg;
            }
        }
    }
    .select-tree-option-placeholder {
        width: 1.25rem;
        height: 1.25rem;
    }
}