.wizard-content {
  .congrats {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .success-content {
    $green: #1F7B3E;
    $white: #F5F5F5;

    $curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

    h1 {
      font-family: 'Roboto';
      font-size: 3rem;
    }

    .success-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 4rem;
    }

    .text-container {
      gap: 1rem;
      margin: 2rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .review-button {
        margin-bottom: 1rem;
      }

      .text-section {
        gap: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }

    .checkmark-container {
      padding: 0.5rem;
    }

    .checkmark {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      display: block;
      stroke-width: 4;
      stroke: $white;
      stroke-miterlimit: 10;
      box-shadow: inset 0px 0px 0px $green;
      animation: fillCheckMark .4s ease-in-out .4s forwards, scaleCheckMark .3s ease-in-out .9s both;
    }

    .checkmark__circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: $green;
      fill: none;
      animation: stroke .6s $curve forwards;
    }

    .checkmark__check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke .3s $curve .8s forwards;
    }

    @keyframes stroke {
      100% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes scaleCheckMark {

      0%,
      100% {
        transform: none;
      }

      50% {
        transform: scale3d(1.1, 1.1, 1);
      }
    }

    @keyframes fillCheckMark {
      100% {
        box-shadow: inset 0px 0px 0px 30px $green;
      }
    }
  }

  .squiggly-arrow {
    position: absolute;
    right: 4rem;
    bottom: 10rem;
  }
}