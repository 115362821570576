.app-popup {
    position: absolute;
    margin: 4rem;
    border-radius: 1rem;
    border: 1px solid var(--primary-border);
    background-color: var(--primary-background);
    z-index: var(--dropdown-z-index);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 12rem);
    width: calc(100% - 26rem);
    min-width: 40rem;

    .close-icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
    }
    .app-list-wrapper {
        flex: 1;
        padding: 1rem;
        width: 100%;
        display: flex;
        justify-content: left;
        .app-list {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            justify-content: center;
            height: fit-content;
            padding: 0 2rem 2rem 2rem;
        }
    }
    .search-wrapper {
        width: 33rem;
        position: relative;
        margin: 2rem;
        
        .field-control__wrapper {
            margin: unset;
        }

        .search-icon {
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.2rem;
            color: var(--primary-border);
            z-index: 1;
        }

        input {
            padding-left: 3rem;
        }
    }
}