.app-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 1rem;
	width: 6rem;
	gap: 0.5rem;
	cursor: pointer;
	font-family: "roboto";

	p {
		text-align: center;
	}

	.app-icon {
		width: 6rem;
		height: 6rem;
		border-radius: 0.5rem;
		object-fit: cover;
		box-shadow: 0px 0px 4px 0px #00000040;
	}

	&.skeleton-box {
		box-shadow: 0px 0px 4px 0px #00000040;
	}
}
